import './style.scss'
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createPinia, setMapStoreSuffix } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueGtag from 'vue-gtag'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { createGtm } from "vue-gtm";

let bugsnagVue =undefined
if (import.meta.env.NODE_ENV=== 'production') {
Bugsnag.start({
  apiKey: '0e9a96641ea079d65eb2ab4a74066216',
  plugins: [new BugsnagPluginVue()],
  notifyReleaseStages: ['production'],
})

  BugsnagPerformance.start({ apiKey: '0e9a96641ea079d65eb2ab4a74066216' })
  bugsnagVue = Bugsnag.getPlugin('vue')
}

// import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import Toast, { type PluginOptions } from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import { socket } from './socket'

// Set options for plugins.
const toastOptions: PluginOptions = {
  maxToasts: 5,
  newestOnTop: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  hideProgressBar: false,
  closeButton: false,
  filterBeforeCreate: (toast: any, toasts: any) => {
    if (toasts.filter((t: any) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast.
      return false
    }
    return toast
  },
}

setMapStoreSuffix('')
const storePinia = createPinia()
storePinia.use(piniaPluginPersistedstate)

createApp(App)

  .use(Toast, toastOptions)
  .use(storePinia)
  .use(router)
  .use(
    VueGtag,
    {
      bootstrap: false,
      appName: 'Y.Pony',
      pageTrackerEnabled: true,
      pageTrackerScreenviewEnabled: true,
      // pageTrackerTemplate(to: { path: string, name: string }) {
      //   return {
      //     page_title: to.name,
      //     page_path: to.path
      //   }
      // },
      config: { id: import.meta.env.VITE_VUE_APP_GOOGLE_ANALYTICS_ID, params: {
        'transport_type': 'beacon'
      } },
    }
  )
  .use(
    createGtm({
      id:  import.meta.env.VITE_VUE_APP_GTM_ID,
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: "2726c7f26c", // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true,
    })
  )
  .use(bugsnagVue)
  .mount('#app')

socket
